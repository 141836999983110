import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import mattressIpsum from 'mattress-ipsum';
import { Row } from './helpers';
import { Fade, Button } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "fade"
    }}>{`Fade`}</h1>
    <p>{`Fade creates a smooth UI/UX for React`}</p>
    <h2 {...{
      "id": "install"
    }}>{`Install`}</h2>
    <p>{`React transition group is needed for Fade`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add react-transition-group
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Fade from '@mfrm/mfcl/Fade'
`}</code></pre>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Fade} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Component\n  initialState={{\n    in: true,\n    duration: 150,\n  }}\n>\n  {({ setState, state }) => {\n    const toggle = () => setState({ in: !state.in })\n    return (\n      <>\n        <Button size=\"md\" onClick={toggle}>\n          Click Me\n        </Button>\n        <Fade in={state.in} duration={state.duration}>\n          {mattressIpsum()}\n        </Fade>\n      </>\n    )\n  }}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Row,
      Fade,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        in: true,
        duration: 150
      }} mdxType="Component">
    {({
          setState,
          state
        }) => {
          const toggle = () => setState({
            in: !state.in
          });
          return <>
          <Button size="md" onClick={toggle} mdxType="Button">
            Click Me
          </Button>
          <Fade in={state.in} duration={state.duration} mdxType="Fade">
            {mattressIpsum()}
          </Fade>
        </>;
        }}
  </Component>
    </Playground>
    <h2 {...{
      "id": "callbacks"
    }}>{`Callbacks`}</h2>
    <Playground __position={2} __code={'<Component\n  initialState={{\n    in: true,\n    firstText: \'\',\n    text: \'\',\n  }}\n>\n  {({ setState, state }) => {\n    const toggle = () => setState({ in: !state.in })\n    return (\n      <>\n        <p>{state.firstText}</p>\n        <p>{state.text}</p>\n        <Button size=\"md\" onClick={toggle}>\n          Click Me\n        </Button>\n        <Fade\n          in={state.in}\n          duration={1000}\n          onEnter={() => setState({ firstText: \'Enter\' })}\n          onEntering={() => setState({ text: \'Entering!\' })}\n          onEntered={() => setState({ text: \'Entered!\' })}\n          onExit={() => setState({ firstText: \'Exit!\' })}\n          onExiting={() => setState({ text: \'Exiting!\' })}\n          onExited={() => setState({ text: \'Exited!\' })}\n        >\n          {mattressIpsum()}\n        </Fade>\n      </>\n    )\n  }}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Row,
      Fade,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        in: true,
        firstText: '',
        text: ''
      }} mdxType="Component">
    {({
          setState,
          state
        }) => {
          const toggle = () => setState({
            in: !state.in
          });
          return <>
          <p>{state.firstText}</p>
          <p>{state.text}</p>
          <Button size="md" onClick={toggle} mdxType="Button">
            Click Me
          </Button>
          <Fade in={state.in} duration={1000} onEnter={() => setState({
              firstText: 'Enter'
            })} onEntering={() => setState({
              text: 'Entering!'
            })} onEntered={() => setState({
              text: 'Entered!'
            })} onExit={() => setState({
              firstText: 'Exit!'
            })} onExiting={() => setState({
              text: 'Exiting!'
            })} onExited={() => setState({
              text: 'Exited!'
            })} mdxType="Fade">
            {mattressIpsum()}
          </Fade>
        </>;
        }}
  </Component>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      